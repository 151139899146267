import $ from 'jquery';
import 'bootstrap';
import 'parsleyjs';
import  Cookies from 'js-cookie';
import "magnific-popup";
import 'jquery-match-height'
import AOS from 'aos';
import Swiper from 'swiper';
import 'bootstrap-select';
import 'simplebar';


window.$ = window.jQuery = $;

let Page = Page || {};
(function($) {

    /* ---------------------------------------------
        Global Variables
    --------------------------------------------- */
    var $window = $(window),
        $html = $('html'),
        $body = $('body');
    
    /* ---------------------------------------------
        APP document Ready
    --------------------------------------------- */
    Page.documentOnReady = {
        init: function() {
            Page.initialize.init();
        }
    };
    
    /* ---------------------------------------------
        Page document Load
    --------------------------------------------- */
    Page.documentOnLoad = {
        init: function() {
            Page.initialize.preloader();
        }
    };
    
    /* ---------------------------------------------
        APP document Resize
    --------------------------------------------- */
    Page.documentOnResize = {
        init: function() {
            var t = setTimeout(function() {
                //
            }, 250);
        }
    };
    
    /* ---------------------------------------------
        Scripts initialization
    --------------------------------------------- */
    $(document).ready( Page.documentOnReady.init);
    $window.on('load', Page.documentOnLoad.init);
    $window.on('resize', Page.documentOnResize.init);
    
    /* ------------------------------------------------------------------------------------------------------------------
        #############################################################################################################
    ------------------------------------------------------------------------------------------------------------------ */

    Page.initialize = {
        init: function() {
            Page.initialize.aos();
            Page.initialize.cookie();
            Page.initialize.header();
            Page.initialize.innerLinks();
            Page.initialize.formValidation();
            Page.initialize.magnificPopup();
            Page.initialize.swiper();
        },
        
        /* ---------------------------------------------
            Preloader
        --------------------------------------------- */
        preloader: function() {
            $body.removeClass("loading");
            $body.addClass("loaded");
        },
        
        /* ---------------------------------------------
            swiper
        --------------------------------------------- */
        swiper: function(){
            if ( $('.swiper-gallery').length > 0 ) {
                $('.swiper-gallery').each(function(i, v) {
                    var $this = $(v);
                    new Swiper(this, {
                        //effect: 'fade',
                        slidesPerView: 2,
                        slidesPerGroup: 1,
                        spaceBetween: 30,
                        simulateTouch: true,
                        loop: true,
                        slideToClickedSlide: true,
                        allowTouchMove: true,
                        uniqueNavElements: false,
                        preventInteractionOnTransition: true,
                        speed: 1200,
                        navigation: {
                            nextEl: $(this).find('.swiper-navigation').find('.swiper-button-next'),
                            prevEl: $(this).find('.swiper-navigation').find('.swiper-button-prev'),
                        },
                        breakpoints: {
                            // when window width is <= 640px
                            576: {
                                slidesPerView: 1,
                                slidesPerGroup: 1,
                            },
                        }
                    });
                });
            }
        },

        
        /* ---------------------------------------------
            Cookie
        --------------------------------------------- */
        cookie: function () {
            let $cookieDiv = $("#cookie");
            let cookie_delay = 3000;
            if ( !Cookies.get('cookie-notyfication') ) {
                setTimeout(function () {
                    $cookieDiv.addClass("show");
                }, cookie_delay);
                $('#cookie-close').on('click', function () {
                    Cookies.set('cookie-notyfication', 'accept', { expires: 365 });
                    $cookieDiv.removeClass("show");
                });
            } 
        },
        
        /* ---------------------------------------------
            Aos
        --------------------------------------------- */
        aos: function() {
            AOS.init({
                // Global settings
                //disable: $(window).width() < 1199, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
                //startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
                initClassName: 'aos-init', // class applied after initialization
                animatedClassName: 'aos-animate', // class applied on animation
                useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
              
                // Settings that can be overriden on per-element basis, by `data-aos-*` attributes:
                offset: 250, // offset (in px) from the original trigger point
                delay: 400, // values from 0 to 3000, with step 50ms
                duration: 450, // values from 0 to 3000, with step 50ms
                easing: 'ease', // default easing for AOS animations
                once: true, // whether animation should happen only once - while scrolling down
                mirror: false, // whether elements should animate out while scrolling past them
                anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
            });

            window.addEventListener('load', AOS.refresh);
        },

        /* ---------------------------------------------
            Form validation
        --------------------------------------------- */
        formValidation: function () {
            //Global validation
            window.Parsley.on('field:error', function() {
                this.$element.addClass('form-control-danger');
            });
                
            window.Parsley.on('field:success', function() {
                this.$element.removeClass('form-control-danger');
                this.$element.addClass('form-control-success');
            });

            // Form gruop heleper classes
            $('form[novalidate]').parsley({
                successClass: "has-success",
                errorClass: "has-danger",
                classHandler: function(el) {
                    return el.$element.closest(".form-group");
                },
                errorsWrapper: '<ul class="parslay-danger"></ul>',
                errorElem: '<li></li>'
            });

            $('form[novalidate]').on('submit', function( evt){
                let form = $(this);
                form.parsley().validate();
                
                if (form.parsley().isValid()){
                    if ( form.attr('data-prevent') === 'true' ){
                        evt.preventDefault();
                    }

                    if ( form.attr('data-modal-show')){
                        var modal = form.attr('data-modal-show');
                        form[0].reset();
                        $(modal + "").modal('show')
                    }
                }
            });
        },

        /* ---------------------------------------------
            magnificPopup
        --------------------------------------------- */
        magnificPopup: function() {
            let $mfpGallery = $('.mfp-gallery');
            if ($mfpGallery.length) {
                $mfpGallery.each(function() { // the containers for all your galleries
                    $(this).magnificPopup({
                        delegate: 'a:not(.disable-mfp)', // the selector for gallery item
                        type: 'image',
                        //mainClass: 'mfp-with-zoom', // this class is for CSS animation below
                        mainClass: 'mfp-zoom-in',
                        showCloseBtn: true,
                        closeBtnInside: true,
                        closeOnContentClick: true,
                        closeMarkup: '<span class="mfp-close"><span class="icon-cross-out"></span></span>',
                        gallery: {
                            enabled: true,
                            arrowMarkup: '<span title="%title%" class="mfp-arrow mfp-arrow-%dir%"></span>', // markup of an arrow button
                        },
                        image: {
                            // titleSrc: function(item) {
                            //     return item.el.find('.overlay-content').html();
                            // }
                        },
                        removalDelay: 300, //delay removal by X to allow out-animation
                        callbacks: {
                            open: function() {
                                //overwrite default prev + next function. Add timeout for css3 crossfade animation
                                $.magnificPopup.instance.next = function() {
                                    var self = this;
                                    self.wrap.removeClass('mfp-image-loaded');
                                    setTimeout(function() { $.magnificPopup.proto.next.call(self); }, 120);
                                }
                                $.magnificPopup.instance.prev = function() {
                                    var self = this;
                                    self.wrap.removeClass('mfp-image-loaded');
                                    setTimeout(function() { $.magnificPopup.proto.prev.call(self); }, 120);
                                }
                            },
                            imageLoadComplete: function() {
                                var self = this;
                                setTimeout(function() { self.wrap.addClass('mfp-image-loaded'); }, 16);
                            }
                        }
                    });
                });
            };

            $('.popup-video').magnificPopup({
                disableOn: 700,
                type: 'iframe',
                mainClass: 'mfp-fade',
                removalDelay: 160,
                preloader: false,
                fixedContentPos: false
            });

            $('[data-lightbox="inline"]').magnificPopup({
                type:'inline',
            });
        },

        /* ---------------------------------------------
            Inner links
        --------------------------------------------- */
        innerLinks: function(){
            if($('.inner-link').length){
                $('.inner-link').on('click', function (e) {
                    e.preventDefault();
                    var href = $(this).attr('href');
                    var speed = parseInt( $(this).attr('data-speed'), 10) || 600;
                    var offset = $(this).attr('data-offset') || $(".header").outerHeight()-1;
                    $('html, body').animate({
                        scrollTop: $(href).offset().top - offset
                    }, speed);
                    $("#toggle-menu").removeClass("is-active");
                    $('#offcanvas').removeClass("active");
                    $('body').removeClass("menu-is-active");
                    setTimeout(function () {
                        var offset = $(this).attr('data-offset') || $(".header").outerHeight()-5;
                        $('html, body').animate({
                            scrollTop: $(href).offset().top - offset
                        }, speed);
                    }, 500);
                    return false;
                });
            }
        },

        /* ---------------------------------------------
            Header, main menu
        --------------------------------------------- */
        header: function () {
            let $offcanvasMenu= $('#offcanvas');
            let $toggleMenu = $("#toggle-menu");
            let scroll_amount = 200;
            
            
            //menu
            $toggleMenu.click(function(){
                $(this).toggleClass("is-active");
                $offcanvasMenu.toggleClass('active');
                $('body').toggleClass("menu-is-active");
            });

            if ( $window.scrollTop() > scroll_amount ){
                $body.addClass("page-scroll");
            }

            $window.scroll(function() {
                if ( $(this).scrollTop() > scroll_amount ){
                    $body.addClass("page-scroll");
                } else{
                    $body.removeClass("page-scroll");
                }
            });
        },
    };
})(jQuery);





